import React, {useState, Fragment} from "react";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {CustomInput, FormGroup, Label, Row} from "reactstrap";
import * as service from "../../../service/crud.service";
import {useForm} from "react-hook-form";
import Col from "reactstrap/es/Col";
import {useHistory} from "react-router-dom";
import {getConfigObject} from "@adaming/ada-react-component";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../../helpers/intlMessages";


const context = "employee_setting";
const Employee = props => {
        let history = useHistory();

        const [modalSuccessShow, setModalSuccessShow] = useState(false);
        const [isLoading, setIsLoading] = React.useState(false);
        const [roles, setRoles] = React.useState([]);
        const [errorMessage, setErrorMessage] = React.useState("");
        const [employeeSetting, setEmployeeSetting] = useState([]);
        const [values, setValues] = React.useState({
            selectedOption: []
        });

        const [selectedRoles, setSelectedRoles] = React.useState(
            initList
        );


        const {register, handleSubmit, errors, setValue, control} = useForm();

        React.useEffect(() => {
            axios.get(`${process.env.REACT_APP_API_URL}/iam-service/roles`, getConfigObject()).then(res => {
                let listRoles = [];
                if (res.data !== undefined && Array.isArray(res.data)) {
                    res.data.forEach(item =>
                        listRoles.push({
                            key: item.id,
                            label: item.name,
                            value: item.id,
                            object: item
                        })
                    );
                    console.log("listRoles 1: ", listRoles);
                    setRoles(listRoles);
                }
            });
        }, []);


        React.useEffect(() => {
            //initList();
        }, []);


        function initList() {
            setIsLoading(true);
            let listSelectedRoles = [];

            axios.get(`${process.env.REACT_APP_API_URL}/mail-service/employee_setting`, getConfigObject()).then(
                res => {
                    console.log("listSelectedRoles 3 : ", res.data);
                    if (res.data !== undefined && res.data[0] !== undefined) {
                        console.log("res.data[0]: ", res.data[0]);
                        setEmployeeSetting(res.data[0]);
                        listSelectedRoles = initSelectedRolesListe(res.data[0]);
                        console.log("listSelectedRoles 111111: ", listSelectedRoles);

                    }
                }
            );
            setIsLoading(false);
            return listSelectedRoles;
        };

        function initSelectedRolesListe(data) {
            let listSelectedRoles = [];
            if (data !== undefined && data.roles !== undefined) {
                data.roles.forEach(item => {
                        listSelectedRoles.push({
                            key: item.id,
                            label: item.name,
                            value: item.id,
                            object: item
                        })
                    }
                );
                setSelectedRoles(listSelectedRoles);
                return listSelectedRoles;

            } else {
                return [];
            }
        }

        const handleMultiChange = selectedOption => {
            employeeSetting.roles = [];
            if (selectedOption) {
                selectedOption.forEach(item => employeeSetting.roles.push(item.object));
            }
            setValues({selectedOption});
            setSelectedRoles(selectedOption);
        };

        const onSubmit = data => {
            console.log("data : ", data);
            console.log("employeeSetting : ", employeeSetting);

            if (employeeSetting.id !== undefined) {
                service.put(`${process.env.REACT_APP_API_URL}/mail-service`, context, () => history.push("/" + context), setErrorMessage, employeeSetting)
            } else {
                service.post(`${process.env.REACT_APP_API_URL}/mail-service`, context, () => history.push("/" + context), setErrorMessage, employeeSetting)
            }

            setModalSuccessShow(true);

        };


        return (
            <Fragment>
                {isLoading ? (
                    <div>Loading ...</div>
                ) : (
                    <div>
                        <div className="row ma-0">
                            <div className="col-sm-12 ptb-15">
                                <div className="roe-card-style">
                                    <div className="roe-card-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col>
                                                    <Label>
                                                        <p style={{color: "red"}}> {errorMessage}</p>
                                                    </Label>
                                                    <FormGroup>
                                                        Rôle des salariés
                                                        <AdaSelect
                                                            name="roles"
                                                            isMulti
                                                            value={selectedRoles}
                                                            options={roles}
                                                            onChange={handleMultiChange}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <AdaButton
                                                            type="submit">
                                                            Valider
                                                        </AdaButton>
                                                        <AdaButton
                                                            style={{marginLeft: 10}}
                                                            onClick={() => history.push('./')}
                                                        >
                                                            Annuler
                                                        </AdaButton>
                                                    </FormGroup>

                                                    <SweetAlert
                                                        success
                                                        show={modalSuccessShow}
                                                        title="Success!"
                                                        onConfirm={() => setModalSuccessShow(false)}
                                                        confirmBtnCssClass="sweet-alert-confirm-button"
                                                        cancelBtnCssClass="sweet-alert-cancle-button"
                                                    >
                                                        <IntlMessages id="action.common.succes"/>
                                                    </SweetAlert>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
            </Fragment>
        );
    }
;
export default Employee;
